'use client'

import { Spinner } from '@/components/common/spinner'
import { signIn } from 'next-auth/react'
import { useEffect } from 'react'

export default function SignIn() {
  useEffect(() => {
    signIn('identity-server')
  }, [])

  return <Spinner />
}
